import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Lottie from 'lottie-react';
import maintenance from '../images/maintenance-mode.json';

const IndexPage = () => (
  <Layout>
    <Seo title="Maintenance mode" />
    <div className="center">
      <div className="lottie-mode">
        <h4>Maintenance mode</h4>
        <Lottie
          animationData={maintenance}
          loop={true}
          className='lottie'
        />
        </div>
    </div>
  </Layout>
);

export default IndexPage
